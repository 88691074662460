
import Vue from 'vue';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';

import theme from 'Theme';
import Group from 'Entities/privatePresenter/Group';
import GroupUser from 'Entities/privatePresenter/GroupUser';
import Icon from 'UI/Icon.vue';
import Guid from 'UI/Guid.vue';
import Dropdown from 'Control/Dropdown.vue';
import Button from 'Control/Button.vue';
import {
    cancelRequest,
    deleteGroup,
    deleteUser,
    inviteUser, leaveGroup,
    MEMBERS_ROLES,
    updateGroup,
    updateMember,
} from 'Store/v2/Groups';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import TextInput from 'Control/TextInput.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import GroupRequest from 'Entities/privatePresenter/GroupRequest';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';

interface Data {
    theme: any;
    roles: MEMBERS_ROLES[];
    showModal: boolean;
    isNameEditing: boolean;
    name: string;
    description: string;
    newUser: {
        email: string;
        roleIndex: number;
    };
    errors: {
        email: boolean;
        name: boolean;
        description: boolean;
    };
}

interface Methods {
    getActiveRoleIndex: (data: string) => number;
    setActiveRole: (data: any, member: GroupUser) => void;
    dateFormatter: (data: string) => string;
    discard: () => void;
    save: () => void;
    checkClickToCloseModal: (data: any) => void;
    setNewUserRole: (data: any) => void;
    invite: () => void;
    deleteGroup: () => void;
    deleteMember: (data: GroupUser) => void;
    getPendingUserRoleIndex: (data: string) => number;
    setPendingMemberRole: (event: any, member: GroupRequest) => void;
    cancelPendingMember: (data: GroupRequest) => void;
    leave: () => void;
    deleteOrLeave: (data: GroupUser) => void;
    setShowModal: () => void;
}

interface Computed {
    groupInfo: Group | null;
    members: GroupUser[];
    pendingMembers: GroupRequest[];
    currentUser: InternalUserResponse | undefined;
    isOwner: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        Icon,
        Guid,
        Dropdown,
        Button,
        TextInput,
    },
    data() {
        return {
            theme,
            roles: [MEMBERS_ROLES.member, MEMBERS_ROLES.admin, MEMBERS_ROLES.creator],
            MEMBERS_ROLES,
            showModal: false,
            isNameEditing: false,
            name: '',
            description: '',
            newUser: {
                email: '',
                roleIndex: 0,
            },
            errors: {
                email: false,
                name: false,
                description: false,
            },
        };
    },
    validations() {
        return {
            newUser: {
                email: {
                    required,
                },
            },
            name: {
                required,
                maxLength: maxLength(20),
                minLength: minLength(3),
            },
            description: {
                maxLength: maxLength(150),
            },
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        groupInfo() {
            return this.$store.state.Groups.managedGroup;
        },
        members() {
            const members = this.groupInfo?.members;
            if (!members) {
                return [];
            }
            return members.sort(({ createdAt: a }, { createdAt: b }) => {
                const aDate = new Date(a).valueOf();
                const bDate = new Date(b).valueOf();
                return aDate - bDate;
            });
        },
        pendingMembers() {
            return this.$store.state.Groups.pendingMembers;
        },
        isOwner() {
            return this.members.some(({ roleName, uid }) => uid === this.currentUser?.id && roleName === MEMBERS_ROLES.creator);
        },
    },
    methods: {
        getActiveRoleIndex(uid) {
            const member = this.members.find(({ uid: memberUid }) => memberUid === uid);
            if (!member) {
                return 0;
            }
            return this.roles.findIndex((role) => role === member.roleName);
        },
        getPendingUserRoleIndex(id) {
            const member = this.pendingMembers.find(({ id: memberId }) => memberId === id);
            if (!member) {
                return 0;
            }
            return this.roles.findIndex((role) => role === member.role);
        },
        async setActiveRole(e, member) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(updateMember({
                    groupId: this.groupInfo?.id ?? '',
                    role: e.item,
                    userId: member.uid,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during changing role');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async setPendingMemberRole(e, member) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(inviteUser({
                    groupId: this.groupInfo?.id ?? '',
                    email: member.recipientEmail,
                    role: e.item,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Role has been successfully changed');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during changing role');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        dateFormatter(dateStr) {
            const date = new Date(dateStr);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        discard() {
            this.showModal = false;
            this.isNameEditing = false;
            this.name = this.groupInfo?.name ?? '';
            this.description = this.groupInfo?.description ?? '';
        },
        async save() {
            this.$v.$touch();
            if (!this.$v.name.$invalid && !this.$v.description.$invalid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(updateGroup({
                        groupId: this.groupInfo?.id ?? '',
                        name: this.name,
                        description: this.description,
                    }));
                    this.showModal = false;
                    this.isNameEditing = false;
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error updating a group');
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else if (this.$v.name.$invalid) {
                this.errors.name = true;
            } else if (this.$v.description.$invalid) {
                this.errors.description = true;
            }
        },
        checkClickToCloseModal(e) {
            if (this.isNameEditing || !this.showModal) {
                return;
            }
            const path = composedPath(e.target);
            if (!path.some((node) => node.classList && Array.from(node.classList).includes('group-name-modal'))) {
                this.showModal = false;
            }
        },
        setNewUserRole({ index }) {
            this.newUser.roleIndex = index;
        },
        async invite() {
            this.$v.$touch();
            if (!this.$v.newUser.email.$invalid) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await this.$store.dispatch(inviteUser({
                        groupId: this.groupInfo?.id ?? '',
                        email: this.newUser.email,
                        role: this.roles[this.newUser.roleIndex],
                    }));
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'User was successfully invited to group');
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error inviting new user');
                    }
                } finally {
                    this.newUser.email = '';
                    this.newUser.roleIndex = 0;
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else {
                this.errors.email = true;
            }
        },
        async deleteGroup() {
            await this.$store.dispatch(deleteGroup(this.groupInfo?.id ?? ''));
        },
        async deleteMember(member) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(deleteUser({
                    userId: member.uid,
                    groupId: this.groupInfo!.id,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'User was successfully deleted from group');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error deleting member');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async cancelPendingMember(member) {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(cancelRequest(member.id));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Request was successfully cancelled');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error cancelling request');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async leave() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                await this.$store.dispatch(leaveGroup(this.groupInfo!.id));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error leaving a group');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async deleteOrLeave(member) {
            if (member.uid === this.currentUser?.id) {
                await this.leave();
                return;
            }
            await this.deleteMember(member);
        },
        setShowModal() {
            if (this.isOwner) {
                this.showModal = !this.showModal;
            }
        },
    },
    async mounted() {
        if (!this.groupInfo) {
            await this.$router.push('/profile/groups-list').catch(() => { /* navigation error */ });
        }
        window.addEventListener('click', this.checkClickToCloseModal);
        this.name = this.groupInfo?.name ?? '';
        this.description = this.groupInfo?.description ?? '';
    },
    beforeDestroy() {
        window.removeEventListener('click', this.checkClickToCloseModal);
    },
});
